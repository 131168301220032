import "./CreateEditSubDepartment.scss";
import { useEffect, useState } from "react";
import Button from "../../../Components/Button/Button";
import { FormControl, MenuItem, Select, TextField } from "@mui/material";
import newRequest from "../../../Utils/newRequest";
import { showErrorMessage } from "../../../Utils/showErrorMessage";
import { message } from "antd";
import Loader from "../../../Components/Loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";

const CreateEditSubDepartment = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const subDepartment = location.state;
  const isCreate = location.pathname.includes("/create");
  const [mainCategories, setMainCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  // Initial examples state with counts and enabled status for each digit length
  const [examples, setExamples] = useState({
    twoDigits: { count: 0, enabled: false },
    threeDigits: { count: 0, enabled: false },
    fourDigits: { count: 0, enabled: false },
    fiveDigits: { count: 0, enabled: false },
    sixDigits: { count: 0, enabled: false },
    sevenDigits: { count: 0, enabled: false },
    eightDigits: { count: 0, enabled: false },
    nineDigits: { count: 0, enabled: false },
    tenDigits: { count: 0, enabled: false },
  });

  const [newSubDepartment, setNewSubDepartment] = useState({
    title: "",
    type_level_id: "",
    training_type_id: "1",
    examples: examples, // Add examples to the state
  });

  const [updatedSubDepartment, setUpdatedSubDepartment] = useState({
    title: subDepartment?.title,
    type_level_id: subDepartment?.type_level_id,
    training_type_id: subDepartment?.type_level?.training_type_id,
    examples: subDepartment?.examples || examples, // Add examples to the state
  });

  useEffect(() => {
    if (subDepartment?.examples) {
      setExamples(subDepartment.examples);
    }
  }, [subDepartment]);

  useEffect(() => {
    const getMainCategories = async () => {
      try {
        const res = await newRequest.get(
          `/type_levels?training_type_id=${
            isCreate
              ? newSubDepartment.training_type_id
              : updatedSubDepartment.training_type_id
          }`
        );
        setMainCategories(res?.data?.data);
      } catch (error) {
        showErrorMessage(error);
      }
    };
    getMainCategories();
  }, [
    newSubDepartment.training_type_id,
    updatedSubDepartment.training_type_id,
  ]);

  const handleChange = (e) => {
    if (isCreate) {
      setNewSubDepartment((prev) => {
        return { ...prev, [e.target.name]: e.target.value };
      });
    } else {
      setUpdatedSubDepartment((prev) => {
        return { ...prev, [e.target.name]: e.target.value };
      });
    }
  };

  // Handle checkbox changes
  const handleCheckboxChange = (field) => {
    setExamples((prev) => {
      const newExamples = {
        ...prev,
        [field]: {
          ...prev[field],
          enabled: !prev[field].enabled,
          // Reset count to 0 when disabling
          count: !prev[field].enabled ? prev[field].count : 0,
        },
      };

      // Update the main state with new examples
      if (isCreate) {
        setNewSubDepartment((prev) => ({ ...prev, examples: newExamples }));
      } else {
        setUpdatedSubDepartment((prev) => ({ ...prev, examples: newExamples }));
      }

      return newExamples;
    });
  };

  // Handle example count changes
  // Handle direct count input changes
  const handleCountChange = (field, value) => {
    if (!examples[field].enabled) {
      return;
    }

    const numValue = parseInt(value) || 0;
    if (numValue >= 0) {
      setExamples((prev) => {
        const newExamples = {
          ...prev,
          [field]: {
            ...prev[field],
            count: numValue,
          },
        };

        // Update the main state with new examples
        if (isCreate) {
          setNewSubDepartment((prev) => ({ ...prev, examples: newExamples }));
        } else {
          setUpdatedSubDepartment((prev) => ({
            ...prev,
            examples: newExamples,
          }));
        }

        return newExamples;
      });
    }
  };

  const handleExampleChange = (field, operation) => {
    if (!examples[field].enabled) {
      message.warning("يرجى تفعيل هذا القسم أولاً");
      return;
    }

    const updateCount = (prevCount) => {
      if (operation === "add") return prevCount + 1;
      if (operation === "subtract") return Math.max(0, prevCount - 1);
      return prevCount;
    };

    setExamples((prev) => {
      const newExamples = {
        ...prev,
        [field]: {
          ...prev[field],
          count: updateCount(prev[field].count),
        },
      };

      // Update the main state with new examples
      if (isCreate) {
        setNewSubDepartment((prev) => ({ ...prev, examples: newExamples }));
      } else {
        setUpdatedSubDepartment((prev) => ({ ...prev, examples: newExamples }));
      }

      return newExamples;
    });
  };

  const validateExamples = () => {
    // Check if any enabled example has a count of 0
    const invalidExamples = Object.entries(examples).filter(
      ([_, value]) => value.enabled && value.count === 0
    );

    if (invalidExamples.length > 0) {
      message.error("يرجى تحديد عدد المسائل للأقسام المفعلة");
      return false;
    }

    // Check if at least one example type is enabled
    const hasEnabledExample = Object.values(examples).some(
      (value) => value.enabled
    );
    if (!hasEnabledExample) {
      message.error("يرجى تفعيل قسم واحد على الأقل");
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateExamples()) {
      return;
    }

    setLoading(true);

    if (isCreate) {
      try {
        await newRequest.post("/level_categories", {
          ...newSubDepartment,
          examples: Object.fromEntries(
            Object.entries(examples)
              .filter(([_, value]) => value.enabled)
              .map(([key, value]) => [key, value.count])
          ),
        });
        message.success("تم اضافة القسم  الفرعي بنجاح");
      } catch (error) {
        showErrorMessage(error);
      } finally {
        setNewSubDepartment({
          title: "",
          type_level_id: "",
          training_type_id: "1",
          examples: {
            twoDigits: { count: 0, enabled: false },
            threeDigits: { count: 0, enabled: false },
            fourDigits: { count: 0, enabled: false },
            fiveDigits: { count: 0, enabled: false },
            sixDigits: { count: 0, enabled: false },
            sevenDigits: { count: 0, enabled: false },
            eightDigits: { count: 0, enabled: false },
            nineDigits: { count: 0, enabled: false },
            tenDigits: { count: 0, enabled: false },
          },
        });
        setLoading(false);
      }
    } else {
      try {
        await newRequest.put(`/level_categories/${subDepartment.id}`, {
          ...updatedSubDepartment,
          examples: Object.fromEntries(
            Object.entries(examples)
              .filter(([_, value]) => value.enabled)
              .map(([key, value]) => [key, value.count])
          ),
        });
        message.success("تم تعديل القسم الفرعي بنجاح");
        navigate("/sub-departments");
      } catch (error) {
        showErrorMessage(error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="create">
      <div className="container">
        <h4 className="title">
          {isCreate ? "اضافه قسم فرعي" : "تعديل قسم فرعي"}
        </h4>
        {loading ? (
          <Loader />
        ) : (
          <form action="" onSubmit={handleSubmit}>
            <div className="form-control"></div>
            <div className="control-group">
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label className="form-label">اسم القسم</label>
                <TextField
                  required
                  fullWidth
                  id="fullWidth"
                  className="form-input"
                  name="title"
                  value={newSubDepartment?.title || updatedSubDepartment?.title}
                  onChange={handleChange}
                />
              </FormControl>

              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label htmlFor="name">تحديد القسم </label>
                <Select
                  inputProps={{ "aria-label": "Without label" }}
                  required
                  name="training_type_id"
                  onChange={handleChange}
                  value={
                    isCreate
                      ? newSubDepartment?.training_type_id
                      : updatedSubDepartment?.training_type_id
                  }
                  className="select"
                >
                  <MenuItem value={1}>عام</MenuItem>
                  <MenuItem value={2}>تفصيلي</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="control-group">
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label htmlFor="name">
                  تحديد القسم الرئيسي / المستوي المضاف الية
                </label>
                <Select
                  inputProps={{ "aria-label": "Without label" }}
                  required
                  className="select"
                  name="type_level_id"
                  value={
                    newSubDepartment?.type_level_id ||
                    updatedSubDepartment?.type_level_id
                  }
                  onChange={handleChange}
                >
                  {mainCategories?.map((item) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            {/* Examples Selection Section */}
            <div className="examples-section">
              <h5 className="examples-title">اختر عدد الارقام و المسائل</h5>
              <div className="examples-grid">
                {/* Two Digits */}
                <div className="example-row">
                  <div className="example-left">
                    <input
                      type="checkbox"
                      checked={examples.twoDigits.enabled}
                      onChange={() => handleCheckboxChange("twoDigits")}
                      className="example-checkbox"
                    />
                    <span className="example-label">مسائل رقمين</span>
                  </div>
                  <div className="example-controls">
                    <button
                      type="button"
                      onClick={() => handleExampleChange("twoDigits", "add")}
                      className={`control-btn ${
                        !examples.twoDigits.enabled ? "disabled" : ""
                      }`}
                      disabled={!examples.twoDigits.enabled}
                    >
                      +
                    </button>
                    <input
                      type="number"
                      min="0"
                      value={examples.twoDigits?.count || 0}
                      onChange={(e) =>
                        handleCountChange("twoDigits", e.target.value)
                      }
                      className="count-input"
                      disabled={!examples.twoDigits.enabled}
                    />
                    <button
                      type="button"
                      onClick={() =>
                        handleExampleChange("twoDigits", "subtract")
                      }
                      className={`control-btn ${
                        !examples.twoDigits.enabled ? "disabled" : ""
                      }`}
                      disabled={!examples.twoDigits.enabled}
                    >
                      -
                    </button>
                  </div>
                </div>

                {/* Three Digits */}
                <div className="example-row">
                  <div className="example-left">
                    <input
                      type="checkbox"
                      checked={examples.threeDigits.enabled}
                      onChange={() => handleCheckboxChange("threeDigits")}
                      className="example-checkbox"
                    />
                    <span className="example-label">مسائل ثلاث ارقام</span>
                  </div>
                  <div className="example-controls">
                    <button
                      type="button"
                      onClick={() => handleExampleChange("threeDigits", "add")}
                      className={`control-btn ${
                        !examples.threeDigits.enabled ? "disabled" : ""
                      }`}
                      disabled={!examples.threeDigits.enabled}
                    >
                      +
                    </button>
                    <input
                      type="number"
                      min="0"
                      value={examples.threeDigits?.count || 0}
                      onChange={(e) => {
                        const value = parseInt(e.target.value) || 0;
                        if (value >= 0) {
                          setExamples((prev) => {
                            const newExamples = {
                              ...prev,
                              threeDigits: {
                                ...prev.threeDigits,
                                count: value,
                              },
                            };
                            if (isCreate) {
                              setNewSubDepartment((prev) => ({
                                ...prev,
                                examples: newExamples,
                              }));
                            } else {
                              setUpdatedSubDepartment((prev) => ({
                                ...prev,
                                examples: newExamples,
                              }));
                            }
                            return newExamples;
                          });
                        }
                      }}
                      className="count-input"
                      disabled={!examples.threeDigits.enabled}
                    />
                    <button
                      type="button"
                      onClick={() =>
                        handleExampleChange("threeDigits", "subtract")
                      }
                      className={`control-btn ${
                        !examples.threeDigits.enabled ? "disabled" : ""
                      }`}
                      disabled={!examples.threeDigits.enabled}
                    >
                      -
                    </button>
                  </div>
                </div>

                {/* Four Digits */}
                <div className="example-row">
                  <div className="example-left">
                    <input
                      type="checkbox"
                      checked={examples.fourDigits.enabled}
                      onChange={() => handleCheckboxChange("fourDigits")}
                      className="example-checkbox"
                    />
                    <span className="example-label">مسائل اربع ارقام</span>
                  </div>
                  <div className="example-controls">
                    <button
                      type="button"
                      onClick={() => handleExampleChange("fourDigits", "add")}
                      className={`control-btn ${
                        !examples.fourDigits.enabled ? "disabled" : ""
                      }`}
                      disabled={!examples.fourDigits.enabled}
                    >
                      +
                    </button>
                    <input
                      type="number"
                      min="0"
                      value={examples.fourDigits?.count || 0}
                      onChange={(e) =>
                        handleCountChange("fourDigits", e.target.value)
                      }
                      className="count-input"
                      disabled={!examples.fourDigits.enabled}
                    />
                    <button
                      type="button"
                      onClick={() =>
                        handleExampleChange("fourDigits", "subtract")
                      }
                      className={`control-btn ${
                        !examples.fourDigits.enabled ? "disabled" : ""
                      }`}
                      disabled={!examples.fourDigits.enabled}
                    >
                      -
                    </button>
                  </div>
                </div>

                {/* Five Digits */}
                <div className="example-row">
                  <div className="example-left">
                    <input
                      type="checkbox"
                      checked={examples.fiveDigits.enabled}
                      onChange={() => handleCheckboxChange("fiveDigits")}
                      className="example-checkbox"
                    />
                    <span className="example-label">مسائل خمس ارقام</span>
                  </div>
                  <div className="example-controls">
                    <button
                      type="button"
                      onClick={() => handleExampleChange("fiveDigits", "add")}
                      className={`control-btn ${
                        !examples.fiveDigits.enabled ? "disabled" : ""
                      }`}
                      disabled={!examples.fiveDigits.enabled}
                    >
                      +
                    </button>

                    <input
                      type="number"
                      min="0"
                      value={examples.fiveDigits?.count || 0}
                      onChange={(e) =>
                        handleCountChange("fiveDigits", e.target.value)
                      }
                      className="count-input"
                      disabled={!examples.fiveDigits.enabled}
                    />
                    <button
                      type="button"
                      onClick={() =>
                        handleExampleChange("fiveDigits", "subtract")
                      }
                      className={`control-btn ${
                        !examples.fiveDigits.enabled ? "disabled" : ""
                      }`}
                      disabled={!examples.fiveDigits.enabled}
                    >
                      -
                    </button>
                  </div>
                </div>

                {/* Six Digits */}
                <div className="example-row">
                  <div className="example-left">
                    <input
                      type="checkbox"
                      checked={examples.sixDigits.enabled}
                      onChange={() => handleCheckboxChange("sixDigits")}
                      className="example-checkbox"
                    />
                    <span className="example-label">مسائل ست ارقام</span>
                  </div>
                  <div className="example-controls">
                    <button
                      type="button"
                      onClick={() => handleExampleChange("sixDigits", "add")}
                      className={`control-btn ${
                        !examples.sixDigits.enabled ? "disabled" : ""
                      }`}
                      disabled={!examples.sixDigits.enabled}
                    >
                      +
                    </button>
                    <input
                      type="number"
                      min="0"
                      value={examples.sixDigits?.count || 0}
                      onChange={(e) =>
                        handleCountChange("sixDigits", e.target.value)
                      }
                      className="count-input"
                      disabled={!examples.sixDigits.enabled}
                    />
                    <button
                      type="button"
                      onClick={() =>
                        handleExampleChange("sixDigits", "subtract")
                      }
                      className={`control-btn ${
                        !examples.sixDigits.enabled ? "disabled" : ""
                      }`}
                      disabled={!examples.sixDigits.enabled}
                    >
                      -
                    </button>
                  </div>
                </div>

                {/* Seven Digits */}
                <div className="example-row">
                  <div className="example-left">
                    <input
                      type="checkbox"
                      checked={examples.sevenDigits.enabled}
                      onChange={() => handleCheckboxChange("sevenDigits")}
                      className="example-checkbox"
                    />
                    <span className="example-label">مسائل سبع ارقام</span>
                  </div>
                  <div className="example-controls">
                    <button
                      type="button"
                      onClick={() => handleExampleChange("sevenDigits", "add")}
                      className={`control-btn ${
                        !examples.sevenDigits.enabled ? "disabled" : ""
                      }`}
                      disabled={!examples.sevenDigits.enabled}
                    >
                      +
                    </button>

                    <input
                      type="number"
                      min="0"
                      value={examples.sevenDigits?.count || 0}
                      onChange={(e) =>
                        handleCountChange("sevenDigits", e.target.value)
                      }
                      className="count-input"
                      disabled={!examples.sevenDigits.enabled}
                    />
                    <button
                      type="button"
                      onClick={() =>
                        handleExampleChange("sevenDigits", "subtract")
                      }
                      className={`control-btn ${
                        !examples.sevenDigits.enabled ? "disabled" : ""
                      }`}
                      disabled={!examples.sevenDigits.enabled}
                    >
                      -
                    </button>
                  </div>
                </div>

                {/* Eight Digits */}
                <div className="example-row">
                  <div className="example-left">
                    <input
                      type="checkbox"
                      checked={examples.eightDigits.enabled}
                      onChange={() => handleCheckboxChange("eightDigits")}
                      className="example-checkbox"
                    />
                    <span className="example-label">مسائل ثماني ارقام</span>
                  </div>
                  <div className="example-controls">
                    <button
                      type="button"
                      onClick={() => handleExampleChange("eightDigits", "add")}
                      className={`control-btn ${
                        !examples.eightDigits.enabled ? "disabled" : ""
                      }`}
                      disabled={!examples.eightDigits.enabled}
                    >
                      +
                    </button>

                    <input
                      type="number"
                      min="0"
                      value={examples.eightDigits?.count || 0}
                      onChange={(e) =>
                        handleCountChange("eightDigits", e.target.value)
                      }
                      className="count-input"
                      disabled={!examples.eightDigits.enabled}
                    />
                    <button
                      type="button"
                      onClick={() =>
                        handleExampleChange("eightDigits", "subtract")
                      }
                      className={`control-btn ${
                        !examples.eightDigits.enabled ? "disabled" : ""
                      }`}
                      disabled={!examples.eightDigits.enabled}
                    >
                      -
                    </button>
                  </div>
                </div>

                {/* Nine Digits */}
                <div className="example-row">
                  <div className="example-left">
                    <input
                      type="checkbox"
                      checked={examples.nineDigits.enabled}
                      onChange={() => handleCheckboxChange("nineDigits")}
                      className="example-checkbox"
                    />
                    <span className="example-label">مسائل تسع ارقام</span>
                  </div>
                  <div className="example-controls">
                    <button
                      type="button"
                      onClick={() => handleExampleChange("nineDigits", "add")}
                      className={`control-btn ${
                        !examples.nineDigits.enabled ? "disabled" : ""
                      }`}
                      disabled={!examples.nineDigits.enabled}
                    >
                      +
                    </button>

                    <input
                      type="number"
                      min="0"
                      value={examples.nineDigits?.count || 0}
                      onChange={(e) =>
                        handleCountChange("nineDigits", e.target.value)
                      }
                      className="count-input"
                      disabled={!examples.nineDigits.enabled}
                    />
                    <button
                      type="button"
                      onClick={() =>
                        handleExampleChange("nineDigits", "subtract")
                      }
                      className={`control-btn ${
                        !examples.nineDigits.enabled ? "disabled" : ""
                      }`}
                      disabled={!examples.nineDigits.enabled}
                    >
                      -
                    </button>
                  </div>
                </div>

                {/* Ten Digits */}
                <div className="example-row">
                  <div className="example-left">
                    <input
                      type="checkbox"
                      checked={examples.tenDigits.enabled}
                      onChange={() => handleCheckboxChange("tenDigits")}
                      className="example-checkbox"
                    />
                    <span className="example-label">مسائل عشرة ارقام</span>
                  </div>
                  <div className="example-controls">
                    <button
                      type="button"
                      onClick={() => handleExampleChange("tenDigits", "add")}
                      className={`control-btn ${
                        !examples.tenDigits.enabled ? "disabled" : ""
                      }`}
                      disabled={!examples.tenDigits.enabled}
                    >
                      +
                    </button>

                    <input
                      type="number"
                      min="0"
                      value={examples.tenDigits?.count || 0}
                      onChange={(e) =>
                        handleCountChange("tenDigits", e.target.value)
                      }
                      className="count-input"
                      disabled={!examples.tenDigits.enabled}
                    />
                    <button
                      type="button"
                      onClick={() =>
                        handleExampleChange("tenDigits", "subtract")
                      }
                      className={`control-btn ${
                        !examples.tenDigits.enabled ? "disabled" : ""
                      }`}
                      disabled={!examples.tenDigits.enabled}
                    >
                      -
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-actions">
              <Button
                text={isCreate ? "اضافة" : "تعديل"}
                size="large"
                variant="fill"
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default CreateEditSubDepartment;
