import "./CreateChampionship.scss";
import { useEffect, useState } from "react";
import Button from "../../../Components/Button/Button";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import newRequest from "../../../Utils/newRequest";
import { message } from "antd";
import { showErrorMessage } from "../../../Utils/showErrorMessage";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Loader from "../../../Components/Loader/Loader";
const CreateChampionship = () => {
  const [levels, setLevels] = useState([]);
  const [selectedLevels, setSelectedLevels] = useState([]);
  const [newChampionship, setNewChampionship] = useState({});
  const [certificateFile, setCertificateFile] = useState(null);
  const [addCertificate, setAddCertificate] = useState(false);
  const [certificatePreview, setCertificatePreview] = useState(null);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getLevels();
    return () => {
      if (certificatePreview) {
        URL.revokeObjectURL(certificatePreview);
      }
    };
  }, []);

  const getLevels = async () => {
    setLoading(true);
    try {
      const res = await newRequest.get("/championship_levels");
      setLevels(res?.data?.data);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const formData = new FormData();
      // Add all existing championship data
      Object.keys(newChampionship).forEach((key) => {
        formData.append(key, newChampionship[key]);
      });
      // Add levels
      formData.append("levels", JSON.stringify(selectedLevels));
      // Add certificate if exists
      if (certificateFile) {
        formData.append("certificate", certificateFile);
      }

      await newRequest.post("/championships", formData);
      message.success("تم اضافة البطولة بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
      setSelectedLevels([]);
      setNewChampionship({});
      setCertificateFile(null);
      setCertificatePreview(null);
      setAddCertificate(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewChampionship((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleCertificateChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCertificateFile(file);
      setCertificatePreview(URL.createObjectURL(file));
    }
  };

  const handleRemoveCertificate = () => {
    setCertificateFile(null);
    setCertificatePreview(null);
  };

  return (
    <div className="create">
      <div className="container">
        <h4 className="title">اضافة بطولة جديدة</h4>
        {loading ? (
          <Loader />
        ) : (
          <form action="" onSubmit={handleSubmit}>
            <div className="control-group">
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label className="form-label"> اسم البطولة</label>
                <TextField
                  placeholder="أدخل اسم البطولة"
                  fullWidth
                  className="form-input"
                  required
                  name="title"
                  onChange={handleChange}
                />
              </FormControl>

              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label className="form-label">سعر البطولة</label>
                <TextField
                  placeholder="أدخل سعر البطولة"
                  fullWidth
                  className="form-input"
                  required
                  name="price"
                  onChange={handleChange}
                />
              </FormControl>

              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label htmlFor="select">نوع البطولة</label>
                <Select
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  required
                  className="select"
                  name="type"
                  value={newChampionship?.type}
                  onChange={handleChange}
                >
                  <MenuItem value="general">عامة</MenuItem>
                  <MenuItem value="special">الطلاب المميزين فقط</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="control-group">
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label htmlFor="select">المستويات</label>
                <Select
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  required
                  className="select"
                  multiple
                  name="type"
                  value={selectedLevels}
                  onChange={(e) => setSelectedLevels(e.target.value)}
                >
                  {levels?.map((item) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item?.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label className="form-label">موعد البطولة</label>
                <TextField
                  placeholder="أدخل موعد البطولة"
                  fullWidth
                  className="form-input"
                  required
                  type="datetime-local"
                  name="start_at"
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 60, // 5 min steps
                  }}
                />
              </FormControl>

              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label className="form-label">مدة البطولة</label>
                <TextField
                  placeholder="أدخل مدة البطولة بالدقائق"
                  fullWidth
                  className="form-input"
                  required
                  type="text"
                  name="training_period"
                  onChange={handleChange}
                />
              </FormControl>
            </div>
            {/* التعديل من هنا  */}
            <div className="control-group">
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label htmlFor="select">نوع المسائل</label>
                <Select
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  required
                  className="select"
                  name="question_type_id"
                  onChange={handleChange}
                >
                  <MenuItem value={1}>رقم رقم</MenuItem>
                  <MenuItem value={2}>مسائلة كاملة</MenuItem>
                  <MenuItem value={3}>ظهور مسألتين</MenuItem>
                </Select>
              </FormControl>

              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label htmlFor="select">نوع الاجابة</label>
                <Select
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  required
                  className="select"
                  name="answer_type"
                  value={newChampionship?.answer_type}
                  onChange={handleChange}
                >
                  <MenuItem value="choice">اختياري</MenuItem>
                  <MenuItem value="text">كتابة نص</MenuItem>
                </Select>
              </FormControl>

              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label htmlFor="select">لغة البطولة</label>
                <Select
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  required
                  className="select"
                  name="language"
                  onChange={handleChange}
                >
                  <MenuItem value="ar">عربي</MenuItem>
                  <MenuItem value="en">انجليزي</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="control-group">
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label className="form-label">الجمله التعريفيه</label>
                <TextField
                  placeholder="الجمله التعريفيه"
                  multiline
                  rows={5}
                  maxRows={5}
                  name="slogan"
                  onChange={handleChange}
                />
              </FormControl>
            </div>

            <div className="control-group">
              <FormControl sx={{ flex: 1 }} className="select">
                <FormControlLabel
                  sx={{ margin: "0" }}
                  control={
                    <Checkbox
                      checked={addCertificate}
                      onChange={(e) => setAddCertificate(e.target.checked)}
                    />
                  }
                  label="اضافة شهادة"
                />

                {addCertificate && (
                  <div style={{ marginTop: "10px" }}>
                    {!certificatePreview ? (
                      <TextField
                        type="file"
                        InputProps={{
                          inputProps: {
                            accept: "image/*",
                          },
                        }}
                        onChange={handleCertificateChange}
                      />
                    ) : (
                      <div
                        style={{ position: "relative", width: "fit-content" }}
                      >
                        <IconButton
                          onClick={handleRemoveCertificate}
                          sx={{
                            position: "absolute",
                            right: -10,
                            top: -10,
                            backgroundColor: "white",
                            "&:hover": { backgroundColor: "#f5f5f5" },
                          }}
                          size="small"
                        >
                          <CloseIcon />
                        </IconButton>
                        <img
                          src={certificatePreview}
                          alt="Certificate preview"
                          style={{
                            maxWidth: "200px",
                            maxHeight: "200px",
                            objectFit: "contain",
                            borderRadius: "4px",
                          }}
                        />
                      </div>
                    )}
                  </div>
                )}
              </FormControl>
            </div>

            <div className="form-actions">
              <Button text="اضافة" size="large" variant="fill" />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default CreateChampionship;
